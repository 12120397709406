.NavMenu nav {
    display: flex;
}

.NavMenu nav > a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        color: #E5DE5A;

        padding: 10px;
        margin: 20px;

        border-radius: 30px 10px;
        width: 100px;
        height: 60px;
        background-color: #202020;
        box-shadow: 0 0 20px 1px #E5DE5A;
    }

    .NavMenu nav > a > span:first-child {
            font-size: 20px;
        }

        .NavMenu nav > a > span:last-child {
            font-size: 12px;
        }